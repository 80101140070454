body,html{
    background:#fcfcfc;
}
body{
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
}
.container{
    max-width: 960px;
}
:root {
    --jumbotron-padding-y: 3rem;
    --input-padding-x: .75rem;
  --input-padding-y: .75rem;
  }
  
  .jumbotron {
    padding-top: var(--jumbotron-padding-y);
    padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: #fff;
  }
  @media (min-width: 768px) {
    .jumbotron {
      padding-top: calc(var(--jumbotron-padding-y) * 2);
      padding-bottom: calc(var(--jumbotron-padding-y) * 2);
    }
  }
  
  .jumbotron p:last-child {
    margin-bottom: 0;
  }
  
  .jumbotron-heading {
    font-weight: 300;
  }
  
  .jumbotron .container {
    max-width: 40rem;
  }
  
  footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  footer p {
    margin-bottom: .25rem;
    margin-top: .25rem;
  }
  
  .box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);}


.form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: 0 auto;
  }
  
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
    height: 50px;
    border-radius: 0px;
  }
  
  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }
  
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  
  .form-label-group input::placeholder {
    color: transparent;
  }
  
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
    border-radius: 0px;
  }
  
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
  }


.logoHd{
    height: 30px;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.card-img-top{
    border-radius: 0px;
}
.navStyle a{
    color:#444;
}
.navStyle a:hover{
    color:tomato;
}
.navbar.navbar-light {
    background-color:#fff!important;
    border-bottom: 2px solid #eee;
    padding:0px;
}
.likePostLine{
    border-top: 1px solid #eee;
    width:100%;
    padding-top:10px;
}
.likePostLine .iconLime:hover > small,
.likePostLine .iconLime:hover{
    color:tomato;
}
.likePostLine .iconLime{
    margin-right: 10px;
    cursor: pointer;
}

.lineHeadPost .usCol{
    padding-top:10px;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:10px;
}
.lineHeadPost .rightLinePost{
    margin-left:10px;
    line-height: 1;
}
.lineHeadPost .rightLinePost .datePost{
    margin-left:10px;
    margin-top:5px;
    font-size:14px;
    color:#999;
}
.lineHeadPost .rightLinePost .locationPost{
    margin-left:10px;
    font-size:14px;
    color:#777;
}
.lineHeadPost .titleHeadPost{
    width: calc(100% - 60px);
}
.lineHeadPost .card-avatar{
    width:60px;
}
.box-card-avatar{
    width:60px;
    height:60px;
    border-radius: 100%;
    overflow: hidden;
}
.usrrow{
    display: flex;
    justify-content: baseline;
    vertical-align: center;
    align-items: center;
}
.lineHeadPost.active .nameUser{
    color:tomato;
}
.lineHeadPost:hover{
    text-decoration: none!important;
}
.lineHeadPost .usrrow:hover a,
.lineHeadPost .usrrow:hover,
.lineHeadPost .usrrow:hover .nameUser{
    text-decoration: none!important;
    color:tomato;
}
.usrrow .biUser{
    margin-left:10px;
    margin-top:2px;
    font-size:14px;
    color:#999;
}
.usrrow .nameUser{
    margin-left:10px;
    color:#333;
    cursor:pointer;
    text-decoration: none;
}
.usrrow .nameUserNoAuth{
    margin-left:10px;
    color:#333;
    text-decoration: none;
}
.mySearchBox .form-search{
    width:100%;
    border:0px;
    border-left:2px solid #ddd;
    padding-left:20px;
    height:30px;
}
.mySearchBox{
    border-radius:0px;
    border-color: #eee;
}
.mySearchBox .card-header{
    background-color:#fff;
    border-color: #f5f5f5;
}
.navbar-brand strong{
    color:tomato;
    margin-right: 20px;
}
.navStyle .active{
    color:tomato;
}

.postCotentLine {
    background: #fff;
    border: 1px solid #eee;
    width:100%;
    margin-bottom:25px;
}
/* .postCotentLine .bottomCont{
    //
} */
.copyPat{
    color:#333;
    text-decoration: underline;
    margin-left:15px;
}
.upPat:hover{
    color: tomato;
}
.upPat{
    color:#333;
    font-size: 20px;
}
.headerHi{
    height:90px;
    width: 100%;
    float: left;
}

.formHref{
    color: tomato;
    cursor: pointer;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active{
    background-color:rgb(218, 87, 64);
    border-color:rgb(218, 87, 64);
}

.btn-primary{
    background-color:tomato;
    border-color:tomato;
    border-radius:0px;
}
.unreedMesssBage{
    margin-top:-8px;
    position: absolute;
}

.noPadding{
    padding: 0rem;
}
.boxScrollChat,
.searchListBox{
    max-height:400px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.twoUsRow{
    margin-bottom:10px;
}

.searchResBox .listCat:hover{
    background-color:#eee;
}
.searchResBox .listCat{
    border-bottom:1px solid #eee;
    padding:10px;
    cursor: pointer;
}
.searchResBox{
    background-color:#fff;
    border:1px solid #eee;
    margin-top:3px;
}
.searchResBox{
    max-height:400px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 100;
    width: calc(100% - 30px);
}

.profileHead{
    border-bottom:1px solid #eee;
    padding-bottom:25px;
    margin-bottom:25px;
}
.profileHead .name{
    font-weight: bold;
    font-size: 25px;
    margin-top:10px;
    color:#333;
}
.profileHead .bio{
    font-size: 17px;
    color:#888;
    margin-bottom:15px;
}
.box-user-avatar{
    width:150px;
    border-radius: 100%;
    border:1px solid #eee;
    height: 150px;
    overflow: hidden;
}
.user-avatar{
    width:150px;
}
.flexProfile{
    display: flex;
    flex-direction: column;
    align-items:center;
}
.subscribeBox{
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
}
.subscribeBox .colSubs:hover{
    border: 1px solid #eee;
}
.subscribeBox .colSubs{
    padding-left:25px;
    padding-right:25px;
    cursor:pointer;
    border: 1px solid transparent;
    border-radius:4px;
}
.btn-light{
    border-color:#eee;
}
.postPhotoProfile{
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
    overflow: hidden;
    background-color:#eee;
    background-position: center;
    background-size: cover;
    margin-bottom:30px;
    cursor: pointer;
}
.postPhotoProfile img{
    width: 100%;
    height: same-as-width;
}
.navStyle.nav-link:hover{
    color:tomato;
}
.navStyle.nav-link{
    color:#333;
}
.boxConnect{
    border:1px solid #eee;
    background-color:#fff;
    padding:25px;
    margin-top:42px;
}
.chatBox{
    width: 100%;
    background: #fff;
    border: 1px solid #eee;
    margin-top: 42px;
}

.boxScrollChat{
    max-height:500px;
    min-height:500px;
    /* border-right:1px solid #eee; */
    width: 100%;
}
.boxChat{
    height: 570px;
    border-left: 1px solid #eee;
    width: 100%;
}
.boxChat .HeaderChat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.boxChat .HeaderChat .biUser{
    color:#999;
    padding-left: 15px;
    font-size:14px;
}
.boxChat .HeaderChat .nameUser{
    padding:15px;
    padding-bottom:0px;
    padding-top:10px;
}
.boxChat .HeaderChat{
    height:68px;
    border-bottom:1px solid #eee;
}
.boxChat .BodyChat{
    height:430px;
    overflow-x: hidden;
    overflow-y: auto;
}
.boxChat .FooterChat{
    height: 70px;
    border-top:1px solid #eee;
}
.boxChat .FooterChat{
    padding:15px;
}
.boxChat .FooterChat .btn:hover{
    color:tomato;
}
.boxChat .FooterChat .input-group .form-control{
    border-color:#eee;
    border-radius:0px;
}

.boxChat .BodyChat .line img{
    border-radius: 100%;
    width:40px;
    height:40px;
    margin:10px;
}
.boxChat .BodyChat .line .mess{
    margin-top:10px;
}
.boxChat .BodyChat .line .date{
    font-size:13px;
    color:#999;
}
.boxChat .BodyChat .line {
    width:100%;
    display: flex;
}
.boxChat .BodyChat .line.user {
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}
.boxChat .BodyChat .line.me {
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
}

.boxWhite{
    width:100%;
    border:1px solid #eee;
    background-color:#fff;
    margin-top:42px;
}
.headerPage{
    width:100%;
    border-bottom:1px solid #eee;
    padding-top:15px;
    padding-bottom:15px;
    padding-left:15px;
}

.line_activity {
    width:100%;
    display: flex;
    padding:15px;
    border-bottom:1px solid #eee;
    justify-content: space-between;
    flex-direction: row;
}
.line_activity .col{
    width:100%;
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
}
.line_activity img{
    width:40px;
}
.line_activity:hover{
    text-decoration: none;
}
.line_activity .mess:hover{
    color:tomato;
}
.line_activity .mess{
    margin-top:0px;
    color:#333;
}
.line_activity .date{
    font-size:13px;
    color:#999;
}
.line_activity .action{
    font-size:14px;
    color:#555;
}

.postAddTextarea {
    border-radius: 0px;
    border-color: #ddd;
}

.switchBox{
    text-align:center;
    height: 50px;
}
.switchBox label{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

/*select search*/
/**
 * Main wrapper
 */
 .select-search {
    width: 100%;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 50px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: #fff;
    border: 1px solid #eee;
    /* box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15); */
    border-radius: 0px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 50px;
    -webkit-appearance: none;
    
}
.signBox .select-search__input{
    padding-bottom: 20px!important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    margin-left:0px;
    padding-left:0px;
    border-radius: 0px;
}
.select-search__select ul{
    padding:0px;
    margin:0px;
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: tomato;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: tomato;
    color: #fff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: tomato;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: tomato;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.btn-sendChat{
    border:1px solid #eee;
    font-size:13px;
    padding: .1rem .75rem;
}

.openCommentBox{
    margin-top:25px;
}

.openCommentBox input{
    border-radius:0px;
}


.appsContainer{
    width:100%;
    text-align:center;
    margin-top:25px;
}
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.app-btn {
    width: 45%;
    max-width: 160px;
    color: #fff;
    margin: 20px 10px;
    text-align: left;
    border-radius: 5px;
    text-decoration: none;
    font-family: "Lucida Grande", sans-serif;
    font-size: 10px;
    text-transform: uppercase;
}
.app-btn.blu {
    background-color: #101010;
    transition: background-color 0.25s linear;
}
.app-btn.blu:hover {
    background-color: #454545;
    color: #fff;
    text-decoration: none;
}
.app-btn i {
    width: 20%;
    text-align: center;
    font-size: 28px;
    margin-right: 7px;
}
.app-btn .big-txt {
    font-size: 17px;
    text-transform: capitalize;
}


.boxScrollChat-btn{
    width: 100%;
    padding: 18px;
    padding-top: 19px;
    border-bottom: 1px solid #eee;
    background-color:#f7f7f7;
    color:#777;
    font-size: 20px;
}

@media (max-width: 768px) {
    .open-mobile{
        display: block!important;
    }
    .chatBox .pl-0{
        padding-left:15px!important;
    }.chatBox .pr-0{
        padding-right:15px!important;
    }
    .boxScrollChat{
        display: none;
    }
    .boxScrollChat-btn{
        display: block;
    }
    .boxChat{
        border-left:0px;
    }
}

.boxLoadMore{
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
}
.btnLoadMore{
    background-color:#fff;
    border:1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    width: auto;
    display: block;
    cursor: pointer;
}
.btnLoadMore:hover{
    background-color:#ddd;
}
.show{
    display: block;
}
.hide{
    display: none;
}
.form-text {
    color: #666;
    font-size: 10px;
}
.boxTabPost{
    width: 100%;
    float: left;
}
.boxTabPost .tab{
    padding: 10px;
    background-color:#eee;
    float: left;
    cursor: pointer;
    margin-right: 1px;
    margin-bottom: 15px;
}
.boxTabPost .tab.active{
    background-color:tomato;
    color: #fff;
}

.btnMedCard:hover{
    background-color:#eee;
    color: #333;
}
.btnMedCard{
    background-color:tomato;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 16px;
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    height: 35px;
    font-size: 12px;
}
.btnMedCard.lock{
    background-color:#eee;
    color: #999;
}
.btnMedCard.lock:hover{
    background-color:#eee;
    color: #999;
    cursor: default;
}

.youtubePostBox{
    display: flex;
    justify-content: center;
    align-items:center;
}
.youtubePost{
    position: absolute;
    top: 50%;
    font-size: 100px;
    margin-top: -50px;
    color: #b14444;
}
.profileBtnMedcard{
    height: 40px;
    font-size: 1rem;
    vertical-align: middle;
    padding: .375rem .75rem;
}
.modal.show{
    background: rgba(0,0,0,0.5);
    overflow-y: auto;
}
.modal-header{
    border-bottom:0px;
}
.modal-body {
    padding: 0px;
}
.modal-content{
    border-radius: 0px;
    border: 0px;
    padding-right: 1px;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
    }
}

.box-foll-avatar{
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    margin-top: 2px;
    overflow:hidden;
}

.plr-25{
    padding-left: 15px;
    padding-right: 15px;
}

.editPostPanel {
    display: flex;
    align-items:center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
}
.editPostPanel .dellBtn,
.editPostPanel .editBtn{
    border:1px solid #dadada;
    width: 30px;
    height: 30px;
    display: flex;
    align-items:center;
    justify-content: center;
    text-decoration: none;
    color: tomato;
    margin-left: 5px;
    cursor: pointer;
}
.editPostPanel .dellBtn:hover,
.editPostPanel .editBtn:hover{
    background-color:tomato;
    border-color:tomato;
    color: #fff;
}
.alertPost{
    border-radius: 0px;
    border:0px;
}
